import React from "react"
import "../../components/my_global.scss"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { Container, Row, Col } from "reactstrap"
import stick from "../../images/stick.webp"
import geoprize from "../../images/geo-prize-graphic.jpg"
import "../../components/prizes.css"
// images
import { StaticImage } from "gatsby-plugin-image"

const Solarround4 = () => {
  return (
    <Layout>
      <SEO title={"What We're Reading"} />
      <div className="page-heading reading-bg">
        <Container>
          <Row>
            <Col lg="8" className="mx-auto text-center text-white">
              <h3 className="h3s"> American-Made Challenges</h3>
              <h2 className="h2s">Solar Prize Round 4</h2>
              <p
                className="page-hero-subheading"
                style={{ textAlign: "justify" }}
              >
                he American-Made Solar Prize is a $3 million prize competition
                designed to energize U.S. solar manufacturing through a series
                of contests and the development of a diverse and powerful
                support network that leverages national laboratories, energy
                incubators, and other resources across the country.
              </p>
              <div className="column is-12">
                <div className="has-text-centered">
                  <a href="https://www.herox.com/solarprizeround4">
                    <button type="button" className="btns">
                      Join the Challenge?
                    </button>
                  </a>
                </div>
                <span className="whitespace"></span>
              </div>
            </Col>
          </Row>
          <StaticImage src="../../images/stick.webp" className="stick" alt="stick" loading="lazy"/>
        </Container>
      </div>
      <div className="section_padding">
        <div className="container">
          <div>
            <h1 className="mac_h1">Round 4 Set! Contest Finalists</h1>
            <hr />
            <h2>
              Congratulations to all of the finalists from the Set! Contest!
              These teams will be awarded both cash prizes and program vouchers
              for use at national laboratories and connector facilities. Round 1
              Finalists and Semifinalists are eligible to compete in the Go!
              Contest.
            </h2>
            <table class="table table-hover">
              <thead>
                <tr>
                  <th>
                    entry title{" "}
                    <span class="is-size-7 has-text-weight-normal">
                      (listed alphabetically)
                    </span>
                  </th>
                  <th className="tds">team</th>
                  <th className="tds">location</th>
                  <th className="tds">subject area</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/solarprizeround4/round/708/entry/34766"
                      className="has-text-light"
                    >
                      Aerogel Insulated Solar Collector for Process Heat
                    </a>
                  </td>
                  <td className="tds">AeroShield Materials</td>
                  <td className="tds">Hyde Park, MA</td>
                  <td className="tds">CSP</td>
                </tr>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/solarprizeround4/round/708/entry/35256"
                      className="has-text-light"
                    >
                      Boosting Energy Yield from Dusty Solar Panel
                    </a>
                  </td>
                  <td className="tds">Superclean Glass</td>
                  <td className="tds">Stony Brook, NY</td>
                  <td className="tds">Photovoltaics</td>
                </tr>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/solarprizeround4/round/708/entry/35233"
                      className="has-text-light"
                    >
                      MORE POWER LESS DIRT
                    </a>
                  </td>
                  <td className="tds">Pellucere Technologies</td>
                  <td className="tds">Houston, TX</td>
                  <td className="tds">Photovoltaics</td>
                </tr>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/solarprizeround4/round/708/entry/35275"
                      className="has-text-light"
                    >
                      NanoSpray: Low Cost Solutions for Back Contacts
                    </a>
                  </td>
                  <td className="tds">NanoSpray</td>{" "}
                  <td className="tds">Brooklyn, NY</td>
                  <td className="tds">Photovoltaics</td>
                </tr>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/solarprizeround4/round/708/entry/35269"
                      className="has-text-light"
                    >
                      Rocking Solar Tracker
                    </a>
                  </td>
                  <td className="tds">Rocking Solar</td>
                  <td className="tds">Monroe, OH</td>
                  <td className="tds">Photovoltaics</td>
                </tr>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/solarprizeround4/round/708/entry/34821"
                      className="has-text-light"
                    >
                      Roofing for the Sol
                    </a>
                  </td>
                  <td className="tds">the r&amp;d lab</td>
                  <td className="tds">Petaluma, CA</td>
                  <td className="tds">Photovoltaics</td>
                </tr>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/solarprizeround4/round/708/entry/35264"
                      className="has-text-light"
                    >
                      Room Temperature Metal Coating for CSP Mirror
                    </a>
                  </td>
                  <td className="tds">Hawaii Innovation Laboratory</td>
                  <td className="tds">Honolulu, HI</td>
                  <td className="tds">CSP</td>
                </tr>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/solarprizeround4/round/708/entry/35280"
                      className="has-text-light"
                    >
                      Saving Solar Power
                    </a>
                  </td>
                  <td className="tds">Energy for All</td>
                  <td className="tds">Elkridge, MD</td>
                  <td className="tds">Photovoltaics</td>
                </tr>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/solarprizeround4/round/708/entry/35268"
                      className="has-text-light"
                    >
                      Solar Panel with Heatsink
                    </a>
                  </td>
                  <td className="tds">Cool Tech Solar</td>
                  <td className="tds">North Oaks, MN</td>
                  <td className="tds">Photovoltaics</td>
                </tr>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/solarprizeround4/round/708/entry/35281"
                      className="has-text-light"
                    >
                      Urban Energy Solar Canopy
                    </a>
                  </td>
                  <td className="tds">Urban Energy</td>
                  <td className="tds">Brooklyn, NY</td>
                  <td className="tds">Photovoltaics</td>
                </tr>
              </tbody>
            </table>
            <div className="has-text-centered">
              <a
                href="https://www.herox.com/SolarPrizeRound4/community"
                target="_blank"
              >
                <button type="button" className="btns">
                  View Set! Contest Semifinalists on HeroX
                </button>
              </a>
            </div>
            <span style={{ padding: "5px" }}></span>
            <p className="pcol-fz">
              The American-Made Solar Prize Round 4 teams worked hard to prepare
              the pitch of their technology at Set! Demo Day,{" "}
              <a href="#">revisit their innovations and progress.</a>
            </p>
          </div>
        </div>
        <span style={{ padding: "5px" }}></span>
        <div className="container">
          <h1 className="mac_h1">Round 4 Ready! Contest Semifinalists</h1>
          <hr />
          <p className="pcol-fz">
            Congratulations to all of the semifinalists from the Ready! Contest!
            Twenty teams across 12 states have been identified as eligible to
            compete in the next two rounds of the Prize competition. These teams
            will be awarded a cash prize and are now eligible to advance in the
            Set! and Go! Contests!
          </p>
          <table class="table table-hover">
            <thead>
              <tr>
                <th>
                  entry title{" "}
                  <span class="is-size-7 has-text-weight-normal">
                    (listed alphabetically)
                  </span>
                </th>
                <th className="tds">team location</th>
                <th className="tds">subject area</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <a
                    href="https://www.herox.com/solarprizeround4/round/677/entry/33108"
                    class="has-text-light"
                  >
                    Aerogel Insulated Solar Collector for Process Heat
                  </a>
                </td>
                <td className="tds">Boston, MA</td>
                <td className="tds">CSP</td>
              </tr>
              <tr>
                <td>
                  <a
                    href="https://www.herox.com/solarprizeround4/round/677/entry/32576"
                    class="has-text-light"
                  >
                    Artificial Intelligence Enabled Battery-Less Solar Hybrid
                    Inverter
                  </a>
                </td>
                <td className="tds">San Jose, CA</td>
                <td className="tds">Systems Integration</td>
              </tr>
              <tr>
                <td>
                  <a
                    href="https://www.herox.com/solarprizeround4/round/677/entry/33184"
                    class="has-text-light"
                  >
                    Boosting Energy Yield from Dusty Solar Panel
                  </a>
                </td>
                <td className="tds">Stony Brook, NY</td>
                <td className="tds">Photovoltaics</td>
              </tr>
              <tr>
                <td>
                  <a
                    href="https://www.herox.com/solarprizeround4/round/677/entry/33113"
                    class="has-text-light"
                  >
                    Harnessing the Sun Using MV SiC Power Devices
                  </a>
                </td>
                <td className="tds">Newtonville, NY</td>
                <td className="tds">Systems Integration</td>
              </tr>
              <tr>
                <td>
                  <a
                    href="https://www.herox.com/solarprizeround4/round/677/entry/33180"
                    class="has-text-light"
                  >
                    Integrated Solar Power and Insulated Wall System
                  </a>
                </td>
                <td className="tds">Easthampton, MA</td>
                <td className="tds">Photovoltaics</td>
              </tr>
              <tr>
                <td>
                  <a
                    href="https://www.herox.com/solarprizeround4/round/677/entry/33166"
                    class="has-text-light"
                  >
                    Lightfoot - Solar Powered Vehicles
                  </a>
                </td>{" "}
                <td className="tds">San Francisco, CA</td>
                <td className="tds">Photovoltaics</td>
              </tr>
              <tr>
                <td>
                  <a
                    href="https://www.herox.com/solarprizeround4/round/677/entry/32922"
                    class="has-text-light"
                  >
                    MORE POWER LESS DIRT
                  </a>
                </td>
                <td className="tds">Corvallis, OR</td>
                <td className="tds">Photovoltaics</td>
              </tr>
              <tr>
                <td>
                  <a
                    href="https://www.herox.com/solarprizeround4/round/677/entry/33183"
                    class="has-text-light"
                  >
                    NanoSpray: Low Cost solutions for Back Contacts
                  </a>
                </td>
                <td className="tds">Brooklyn, NY</td>
                <td className="tds">Photovoltaics</td>
              </tr>
              <tr>
                <td>
                  <a
                    href="https://www.herox.com/solarprizeround4/round/677/entry/33182"
                    class="has-text-light"
                  >
                    Next-Gen Solar Roofing Product Collaboration
                  </a>
                </td>
                <td className="tds">Highland, NY</td>
                <td className="tds">Photovoltaics</td>
              </tr>
              <tr>
                <td>
                  <a
                    href="https://www.herox.com/solarprizeround4/round/677/entry/33089"
                    class="has-text-light"
                  >
                    PV Cost-Effective Eco-Friendly Recycling SPWR
                  </a>
                </td>
                <td className="tds">Austin, TX</td>
                <td className="tds">Photovoltaics</td>
              </tr>
              <tr>
                <td>
                  <a
                    href="https://www.herox.com/solarprizeround4/round/677/entry/32363"
                    class="has-text-light"
                  >
                    Rocking Solar Tracker
                  </a>
                </td>
                <td className="tds">Mason, OH</td>
                <td className="tds">Photovoltaics</td>
              </tr>
              <tr>
                <td>
                  <a
                    href="https://www.herox.com/solarprizeround4/round/677/entry/32884"
                    class="has-text-light"
                  >
                    Roofing for the Sol
                  </a>
                </td>
                <td className="tds">Petaluma, CA</td>
                <td className="tds">Photovoltaics</td>
              </tr>
              <tr>
                <td>
                  <a
                    href="https://www.herox.com/solarprizeround4/round/677/entry/33161"
                    class="has-text-light"
                  >
                    Room Temperature Metal Coating for CSP Mirror
                  </a>
                </td>
                <td className="tds">Honolulu, HI</td>
                <td className="tds">CSP</td>
              </tr>
              <tr>
                <td>
                  <a
                    href="https://www.herox.com/solarprizeround4/round/677/entry/33193"
                    class="has-text-light"
                  >
                    Saving Solar Power
                  </a>
                </td>
                <td className="tds">Columbia, MD</td>
                <td className="tds">Photovoltaics</td>
              </tr>
              <tr>
                <td>
                  <a
                    href="https://www.herox.com/solarprizeround4/round/677/entry/31000"
                    class="has-text-light"
                  >
                    Self Cleaning Solar Power
                  </a>
                </td>
                <td className="tds">Boston, MA</td>
                <td className="tds">Photovoltaics</td>
              </tr>
              <tr>
                <td>
                  <a
                    href="https://www.herox.com/solarprizeround4/round/677/entry/32452"
                    class="has-text-light"
                  >
                    Solar Air Wall
                  </a>
                </td>
                <td className="tds">Merritt Island, FL</td>
                <td className="tds">CSP</td>
              </tr>
              <tr>
                <td>
                  <a
                    href="https://www.herox.com/solarprizeround4/round/677/entry/33185"
                    class="has-text-light"
                  >
                    Solar Panel with Heatsink
                  </a>
                </td>
                <td className="tds">North Oaks, MN</td>
                <td className="tds">Photovoltaics</td>
              </tr>
              <tr>
                <td>
                  <a
                    href="https://www.herox.com/solarprizeround4/round/677/entry/33098"
                    class="has-text-light"
                  >
                    Stable and Scalable Perovskite Solar Cells
                  </a>
                </td>
                <td className="tds">West Lafayette, IN</td>
                <td className="tds">Photovoltaics</td>
              </tr>
              <tr>
                <td>
                  <a
                    href="https://www.herox.com/solarprizeround4/round/677/entry/33164"
                    class="has-text-light"
                  >
                    The Mighty Power System™
                  </a>
                </td>
                <td className="tds">Monmouth County, NJ</td>
                <td className="tds">Photovoltaics</td>
              </tr>
              <tr>
                <td>
                  <a
                    href="https://www.herox.com/solarprizeround4/round/677/entry/32966"
                    class="has-text-light"
                  >
                    Urban Energy Solar Canopy
                  </a>
                </td>
                <td className="tds">Brooklyn, NY</td>
                <td className="tds">Photovoltaics</td>
              </tr>
            </tbody>
          </table>
          <div className="has-text-centered">
            <a
              href="https://www.herox.com/GeothermalManufacturing/community"
              target="_blank"
            >
              <button type="button" className="btns">
                View Set! Contest Semifinalists on HeroX
              </button>
            </a>
          </div>
        </div>
        <span style={{ padding: "5px" }}></span>
        <div className="container">
          <div>
            <h1 className="mac_h1">Important Dates Round 4</h1>
            <hr />
            <ul className="boxy-bullets gold-bullets ps list-styled">
              <li>Submission opens: July 10, 2020</li>
              <li>
                Ready! Contest submission deadline: October 8, 2020 2:00 PM ET
              </li>
              <li>
                Ready! winners announced and Set! Contest begins: December 4,
                2020
              </li>
              <li>
                Set! Contest winners announced and Go! Contest begins: April 9,
                2021
              </li>
              <li>Go! Contest winners announced: anticipated September 2021</li>
            </ul>
            <p className="ps">
              Sign up for updates on our{" "}
              <a
                href="https://www.herox.com/SolarPrizeround4"
                onclick="gtag('event', 'action', {'event_category': 'navigated to herox', 'event_label': 'linked to erobot'})"
              >
                HeroX challenge page
              </a>
              .
            </p>
            <b class="is-size-7">
              All dates are subject to change including contest openings,
              deadlines, and announcements.
            </b>
          </div>
          <hr />
        </div>
        <div className="container">
          <h3 className="h3ps mac_h1">
            {" "}
            Solutions that help shape the future{" "}
          </h3>
          <p className="hps">
            Are you a thinker, entrepreneur, facility or potential partner?
            Anyone with an innovative idea can help transform the solar industry
            through the American-Made Solar Prize.
          </p>
          <div style={{ textAlign: "center" }}>
            <a href="https://www.herox.com/solarprizeround4">
              <button type="button" className="btns">
                Ready for a Challenge?
              </button>
            </a>
          </div>
        </div>

        <span style={{ padding: "5px" }}></span>
      </div>
    </Layout>
  )
}

export default Solarround4
